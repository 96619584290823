import { ChatStatus, IChatEventBrief, IKnowledgeBaseResponse } from '../types';

export const getIsPresentKnowledgeBase = (
	knowledgeBaseResponse: IKnowledgeBaseResponse,
) => knowledgeBaseResponse
	&& (knowledgeBaseResponse.folders?.length > 0 || knowledgeBaseResponse.articles?.length > 0);

export const prepareMessageText = (text: string) => {
	let currText = text || '';
	let buttons: string[] = [];

	const btnRegex = /<btn>\[[^\]]+]\(https?:\/\/[^)]+\)/g;
	const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

	const matcher = text.match(btnRegex);

	if (matcher) {
		buttons = matcher;

		buttons.forEach((button) => {
			currText = currText.replace(button, '');
		});
	}

	currText = currText.replace(urlRegex, (url: string, b: string, c: string) => {
		const url2 = c === 'www.' ? `http://${url}` : url;
		return `<a href="${url2}" target="_blank" class="noStyles link">${url}</a>`;
	});

	currText += buttons.length ? '<div class="buttons">' : '';

	buttons.forEach((button: string) => {
		const [textBtn, urlBtn]: string[] = button
			.replace('<btn>[', '')
			.replace(')', '')
			.split('](');

		const type = textBtn.startsWith('{') && textBtn.endsWith('}')
			? textBtn.replace('{', '').replace('}', '')
			: '';

		const modification = type ? ` -${type}` : '';

		const className = `"customButton${modification}"`;

		currText += `<a href="${urlBtn}" target="_blank" class=${className}>${textBtn}</a>`;
	});

	currText += buttons.length ? '</div>' : '';

	currText = currText.replace(/ {2,}/g, ' ');

	return currText;
};

export const getTitleForLiveChatHeader = (history: IChatEventBrief[], defaultText: string) => {
	let status: ChatStatus | string = '';

	[...history].reverse().forEach((item) => {
		if (status) return;

		if (item.type === 'chat_closed') {
			status = 'chat_closed';

			return;
		}

		const localStatus = item?.status || item?.chat_status;

		if (localStatus) {
			status = localStatus;
		}
	});

	const operatorName = [...history].reverse().find((i) => i.operator_name)?.operator_name;

	return status === 'open' ? (operatorName || defaultText) : defaultText;
};