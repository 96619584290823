import { Dispatch, SetStateAction } from 'react';
import { SYSTEM_MESSAGES } from '../enums';
import { IChatEventBrief, MessageStatus } from '../types';
import {
	constructMessageData,
	isMessageSendedToday,
	today,
} from './helpers';

interface Params {
	message: {
		text: string,
		delay: number,
		status: MessageStatus
	},
	liveChatOpenedAfterFirstAutoReply: boolean,
	chatStatus: string,
	setIsFirstMessagesProgress: Dispatch<SetStateAction<boolean | null>>,
	setHistory: Dispatch<SetStateAction<IChatEventBrief[]>>,
	setSystemMessages: Dispatch<SetStateAction<IChatEventBrief[] | []>>,
	setTimeoutId: Dispatch<SetStateAction<null | NodeJS.Timeout>>,
	chatStartedOrigin: string,
	chatHistory: IChatEventBrief[],
}

export const handleFirstMessage = (props: Params) => {
	const {
		message,
		liveChatOpenedAfterFirstAutoReply,
		chatStatus,
		setIsFirstMessagesProgress,
		setHistory,
		setSystemMessages,
		setTimeoutId,
		chatStartedOrigin,
		chatHistory,
	} = props;

	const DELAY_SENDED_MSG = 500;

	const firstMessage = constructMessageData(message) as IChatEventBrief;

	const firstMessageWithDelayKey = `${SYSTEM_MESSAGES['live_chat_delayed.first_message']}-${chatStartedOrigin}`;
	const isSendedTodayWithDelay = isMessageSendedToday(SYSTEM_MESSAGES['live_chat_delayed.first_message'], chatStartedOrigin);
	const isSendedTodayWithOutDelay = isMessageSendedToday(SYSTEM_MESSAGES['all_channels_instant.first_message'], chatStartedOrigin);

	const showMessage = (delay: number) => {
		const timeoutId = setTimeout(() => {
			localStorage.setItem(firstMessageWithDelayKey, today);
			setIsFirstMessagesProgress(true);
			setHistory((prev: IChatEventBrief[]) => [...prev, firstMessage]);
			setSystemMessages((prev) => [...prev, firstMessage]);
		}, delay);
		setTimeoutId(timeoutId);
	};

	if (chatStatus === '' && chatHistory.length === 0 && !isSendedTodayWithOutDelay) {
		const delayValue = isSendedTodayWithDelay
    || liveChatOpenedAfterFirstAutoReply
			? DELAY_SENDED_MSG : message.delay * 1000;
		setIsFirstMessagesProgress(false);
		showMessage(delayValue);
	}

	if (chatStatus === 'closed' && !isSendedTodayWithDelay) {
		showMessage(message.delay * 1000);
		setIsFirstMessagesProgress(false);
	}
};